<template>
  <div>
    <a-card>
    <a-result status="error" title="禁止访问" sub-title="您没有权限访问此页面">
      <template #icon>
        <a-icon type="stop" />
      </template>
    </a-result>
    </a-card>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/parts/HelloWorld'

export default {
  name: 'Index',
  // components: {
  //   HelloWorld
  // },
  data() {
    return {
      stats: {
        current: 2019, months: [],
        yestoday:{ charges: 0, withdraws: 0 },
        day:{ charges: 0, withdraws: 0 },
        month:{ charges: 0, withdraws: 0 },
        year:{ charges: 0, withdraws: 0 }
      },
      item: {
        id: 0, staff: { name: 'sss' }
      }
    }
  },
  computed: {
    staff () {
      return this.$store.state.staff;
    }
  },
  mounted() {
    // this.request();
  },
  methods: {
    request(year) {
      this.$get('/index', { year: year }).then(res=>{
        let ajax = res.data;
        if (ajax.data) {
          for (let k in ajax.data) {
            let vv = ajax.data[k];
            this.stats[k] = vv;
          }
        }
      })
    },

    changeYear(year) {
      this.request(year);
    }
  }
}
</script>

<style type="less" scoped>
  .stats td {

  }
  .stats td small {
    text-align: center; color: #999;
    font-size: 12px; font-weight: normal;
  }
  .stats td div {
    text-align: center; padding-top: 8px;
    font-size: 18px; font-weight: bold;
  }
  .aaaa div {
    color: green;
  }
  .bbbb div {
    color: #2b55cc;
  }
  .cccc div {
    color: red;
  }
</style>